import React from 'react';
import PropTypes from 'prop-types';
import { DialogFormFrame } from 'components/Base';
import CreateUserGroupForm from './CreateUserGroupForm';
import { createUserGroup } from 'state/actions';
import { useLazyLoading } from 'state/hooks';
import { StringParam, useQueryParam } from 'use-query-params';

const CreateUserGroupDialog = ({ open, onClose }) => {
  const [, setUserGroupId] = useQueryParam('userGroupId', StringParam);

  const [handleSubmit, { loading }] = useLazyLoading({
    action: createUserGroup,
    onSuccess: (result) => {
      onClose();
      setUserGroupId(result.id);
    },
  });

  return (
    <DialogFormFrame
      onClose={onClose}
      open={open}
      title={'Add new User Group'}
      formComponent={
        <CreateUserGroupForm
          loading={loading}
          onClose={onClose}
          onSubmit={handleSubmit}
        />
      }
    />
  );
};

CreateUserGroupDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateUserGroupDialog;
