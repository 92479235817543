import React from 'react';
import PropTypes from 'prop-types';
import { DialogFormFrame } from 'components/Base';
import VerifyEmailAddressForm from './VerifyEmailAddressForm';
import { useLazyLoading } from 'state/hooks';
import { verifyEmailAddress } from 'state/actions';

const VerifyEmailAddressDialog = ({ open, onClose, mail }) => {
  const [handleSubmit, { loading }] = useLazyLoading({
    action: verifyEmailAddress,
    onSuccess: () => onClose(),
    onError: () => {
      onClose();
    },
  });

  return (
    <DialogFormFrame
      title={mail ? 'Resend Link' : 'Verify new mail address'}
      open={open}
      onClose={onClose}
      formComponent={
        <VerifyEmailAddressForm
          onSubmit={handleSubmit}
          mail={mail}
          loading={loading}
        />
      }
    />
  );
};

VerifyEmailAddressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default VerifyEmailAddressDialog;
