import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAsyncAction } from 'state';
import {
  addAwsCredentials,
  evaluateAwsPermissions,
  updateAwsCredentials,
  updateMemberUserGroups,
  clearUpgradeDialog,
  loadCompanyInfo,
  createTemplate,
  fetchCompanyMembers,
  inviteCompanyMember,
  showUpgradeDialog,
  upgradePlan,
} from 'state/actions';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { DEFAULT_PLAN_ID, DEFAULT_PLAN_NAME } from 'consts/defaults';
import { useLazyLoading } from 'state/hooks';
import { Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { AsyncDialogActions, DialogFormFrame } from 'components/Base';
import AwsCredentialsForm from 'components/Menu/CompanySettings/Aws/Credentials/AwsCredentialsForm';
import CompanyMemberForm from 'components/Menu/CompanySettings/TeamAndAccess/CompanyMemberForm';
import CreateForm from 'components/Menu/Templates/Overview/CreateForm';
import UpgradeSubscriptionDialog from 'components/UpgradeDialog/UpgradeSubscriptionDialog';
import { isCompanyEmail } from 'components/utils';
import { googleAdsConversionTypes } from 'consts';
import { useConversions } from './ConversionContext';

// Dialogs
const dialogContent = {
  alertTitle: 'Plan limit exceeded!',
  freemiumAlerts: {
    aws:
      'You have exceeded the maximum number of AWS connections on your current plan. Upgrade your plan to add additional connections and start working across them.',
    user:
      'You have exceeded the maximum number of users on your current plan. Upgrade your plan to invite additional users and start collaborating.',
    template:
      'You have exceeded the maximum number of templates on your current plan. Upgrade your plan to create additional templates.',
  },
  alerts: {
    aws:
      'You have exceeded the maximum number of AWS connections on your current plan. Additional AWS connections will be charged at 10$ per month.',
    user:
      'You have exceeded the maximum number of users on your current plan. Any additional user will be charged at 5$ per month.',
  },
};

const dialogTitles = {
  aws: 'Set your AWS SES credentials',
  user: 'Invite new team member',
  template: 'Create a new template',
};

const LimitExceededComponent = ({ type, showUpgradeDialog, handleSubmit }) => {
  if (showUpgradeDialog) {
    return (
      <Fragment>
        <Box mb={2}>
          <Alert severity="warning">
            <AlertTitle>{dialogContent.alertTitle}</AlertTitle>
            {dialogContent.freemiumAlerts[type]}
          </Alert>
        </Box>
        <AsyncDialogActions
          onClose={null}
          loading={false}
          submitText={'Upgrade'}
          onSubmit={handleSubmit}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Box mb={2}>
        <Alert severity="warning">
          <AlertTitle>{dialogContent.alertTitle}</AlertTitle>
          {dialogContent.alerts[type]}
        </Alert>
      </Box>
      <AsyncDialogActions
        loading={false}
        submitText={'Accept'}
        onSubmit={handleSubmit}
      />
    </Fragment>
  );
};

const SubscriptionContext = createContext(null);

export const useSubscription = () => useContext(SubscriptionContext);

const SubscriptionProvider = ({ children, company, subscription, user }) => {
  const dispatch = useDispatch();
  const { sendConversion } = useConversions();
  const history = useHistory();
  const { data, loading } = useAsyncAction({ action: loadCompanyInfo });
  const [awsAccountLimitExceeded, setAwsAccountLimitExceeded] = useState(false);
  const [templateLimitExceeded, setTemplateLimitExceeded] = useState(false);
  const [userLimitExceeded, setUserLimitExceeded] = useState(false);
  const [isDefaultPlan, setIsDefaultPlan] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const [scopedIdentity, setScopedIdentity] = useState(null);
  const [accountAlias, setAccountAlias] = useState(null);
  const [receivedAccountData, setReceivedAccountData] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedCredentials, setSelectedCredentials] = useState(null);

  useEffect(() => {
    if (subscription) {
      setAwsAccountLimitExceeded(
        subscription.awsAccountsCount >= subscription.awsAccountLimit
      );
      setTemplateLimitExceeded(
        subscription.templates.count >= subscription.templateLimit
      );
      setUserLimitExceeded(subscription.userCount >= subscription.userLimit);
      setIsDefaultPlan(subscription.id === DEFAULT_PLAN_ID);
    }
  }, [
    subscription,
    setAwsAccountLimitExceeded,
    setTemplateLimitExceeded,
    setUserLimitExceeded,
    setIsDefaultPlan,
  ]);

  const upgradeDialogOpen = useSelector(
    (state) => state.layout?.upgradeDialogOpen || false
  );

  // Resets states when dialog is closed.
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setShowConfirmation(false);
        setScopedIdentity(null);
        setDialogType(null);
      }, 250);
    }
  }, [dialogOpen, setDialogType, setScopedIdentity]);

  const [handleUpgrade, { loading: submitUpgradeLoading }] = useLazyLoading({
    action: upgradePlan,
    onSuccess: () => {
      // TODO: move this to new subscribe/success page so it is only thrown after user actually paid
      try {
        if (user?.gclid) {
          sendConversion(
            user.gclid,
            isCompanyEmail(user.email)
              ? googleAdsConversionTypes.UPGRADE_REQUESTED_COMPANY_SUBSCRIBER
              : googleAdsConversionTypes.UPGRADE_REQUESTED_SUBSCRIBER
          );
        }
      } catch (e) {
        console.log('conversion signal not sent');
      }
    },
  });

  const onHandleUpgrade = async (vars) => {
    await handleUpgrade(vars);
    const baseUrl = 'https://payments.semplates.io/b';
    const env =
      process.env.REACT_APP_API_ENDPOINT.includes('dev') ||
      process.env.REACT_APP_API_ENDPOINT.includes('localhost')
        ? 'dev'
        : 'prd';
    if (vars.annualBilling && vars.selectedPlan === 'starter') {
      window.open(`${baseUrl}/6oE7uPaY127y5Ne7sw`);
    } else if (vars.annualBilling && vars.selectedPlan === 'business') {
      window.open(`${baseUrl}/00g8yT4zDbI80sU28e`);
    } else if (!vars.annualBilling && vars.selectedPlan === 'starter') {
      window.open(`${baseUrl}/8wM7uP9TX8vW0sU4gl`);
    } else if (!vars.annualBilling && vars.selectedPlan === 'business') {
      if (env === 'dev') {
        window.open(`${baseUrl}/test_cN2bIJfTZePSfWofYY`);
      } else {
        window.open(`${baseUrl}/14kcP90jn7rSa3ufZ5`);
      }
    }
  };

  const [handleEvaluatePermissions] = useLazyLoading({
    action: evaluateAwsPermissions,
  });

  useEffect(() => {
    if (receivedAccountData) {
      handleEvaluatePermissions(receivedAccountData.id, true);
      setAccountAlias(null);
      setReceivedAccountData(null);
    }
  }, [accountAlias, receivedAccountData, handleEvaluatePermissions]);

  const [handleSubmitAwsCredentials, { loading: awsLoading }] = useLazyLoading({
    action: addAwsCredentials,
    onSuccess: (data) => {
      if (user?.gclid) {
        sendConversion(
          user.gclid,
          isCompanyEmail(user.email)
            ? googleAdsConversionTypes.AWS_CONNECTED_COMPANY_SUBSCRIBER
            : googleAdsConversionTypes.AWS_CONNECTED_SUBSCRIBER
        );
      }
      setReceivedAccountData(data);
      handleCloseDialogs();
    },
  });

  const [handleFetchCompanyMembers] = useLazyLoading({
    action: fetchCompanyMembers,
  });

  const [
    handleUpdateAwsCredentials,
    { loading: awsCredentialsUpdating },
  ] = useLazyLoading({
    action: updateAwsCredentials,
    onSuccess: () => {
      handleCloseDialogs();
    },
  });

  const handleSubmitOrUpdateAwsCredentials = async (values) => {
    setAccountAlias(values.alias);

    if (!selectedCredentials?.alias) {
      await handleSubmitAwsCredentials(values);
    } else {
      await handleUpdateAwsCredentials({
        id: selectedCredentials.id,
        values,
      });
    }
  };

  const [handleSubmitMember, { memberLoading }] = useLazyLoading({
    action: inviteCompanyMember,
    onSuccess: () => {
      handleCloseDialogs();
      handleFetchCompanyMembers();
    },
  });

  const [handleUpdateMember, { loading: updatingMember }] = useLazyLoading({
    action: updateMemberUserGroups,
    onSuccess: () => {
      handleCloseDialogs();
      handleFetchCompanyMembers();
    },
  });

  const [
    createTemplateAction,
    { loading: createTemplateLoading },
  ] = useLazyLoading({
    action: createTemplate,
    onSuccess: (uid) => {
      handleCloseDialogs();
      history.push(`/templates/${uid}/editor`);
    },
  });

  // Prepare payload and create template.
  const handleCreateTemplate = async ({ name, subject, tags }) => {
    createTemplateAction({
      sample_name: scopedIdentity.name,
      template_name: name,
      subject,
      tags,
    });
  };

  // Change user groups
  const handleMemberUserGroup = async (values) => {
    await handleUpdateMember(scopedIdentity.uid, values.userGroupIds);
  };

  // Upgrade account dispatch
  const openUpgradeDialog = () => {
    dispatch(showUpgradeDialog());
  };

  // Upgrade account dispatch
  const closeUpgradeDialog = () => {
    dispatch(clearUpgradeDialog());
  };

  const openAwsDialog = (credentials) => {
    setSelectedCredentials(credentials);
    setDialogType('aws');
    if (!credentials?.alias) {
      // show confirmation as needed.
      setLimitExceeded(awsAccountLimitExceeded);
      // only show if new account, not when editing
      setShowConfirmation(awsAccountLimitExceeded);
    }
    setDialogOpen(true);
  };

  const openUserDialog = (member) => {
    setDialogType('user');
    if (member) {
      setScopedIdentity(member);
      setShowConfirmation(false);
    } else {
      // show confirmation as needed.
      setLimitExceeded(userLimitExceeded);
      setShowConfirmation(userLimitExceeded);
    }
    setDialogOpen(true);
  };

  const openTemplateDialog = (template) => {
    setDialogType('template');
    setScopedIdentity(template);
    setLimitExceeded(templateLimitExceeded);
    // Never show confirmation for templates.
    setShowConfirmation(false);
    setDialogOpen(true);
  };

  const handleCloseDialogs = () => {
    setSelectedCredentials(null);
    setDialogOpen(false);
  };

  const getDialogComponent = () => {
    if (isDefaultPlan && limitExceeded) {
      return (
        <LimitExceededComponent
          type={dialogType}
          showUpgradeDialog={true}
          handleSubmit={() => {
            handleCloseDialogs();
            openUpgradeDialog();
          }}
        />
      );
    }
    if (showConfirmation) {
      return (
        <LimitExceededComponent
          type={dialogType}
          showUpgradeDialog={false}
          handleSubmit={() => setShowConfirmation(false)}
        />
      );
    }
    switch (dialogType) {
      case 'aws':
        return (
          <AwsCredentialsForm
            confirmAdditionalCosts={awsAccountLimitExceeded}
            loading={awsLoading || awsCredentialsUpdating}
            onClose={handleCloseDialogs}
            onSubmit={handleSubmitOrUpdateAwsCredentials}
            credentials={selectedCredentials}
            externalId={company?.details.uid}
          />
        );
      case 'user':
        return (
          <CompanyMemberForm
            confirmAdditionalCosts={userLimitExceeded}
            loading={memberLoading || updatingMember}
            onClose={handleCloseDialogs}
            member={scopedIdentity}
            onSubmit={
              scopedIdentity ? handleMemberUserGroup : handleSubmitMember
            }
          />
        );

      case 'template':
        return (
          <CreateForm
            loading={createTemplateLoading}
            onClose={handleCloseDialogs}
            input={scopedIdentity}
            onSubmit={handleCreateTemplate}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <SubscriptionContext.Provider
      value={{
        company,
        loading,
        isDefaultPlan,
        awsConnected: data?.summary?.awsConnected || false,
        progress: data?.summary?.progress || 0,
        subscription: {
          ...data?.subscription,
          name: data?.subscription?.name || DEFAULT_PLAN_NAME,
        },
        closeUpgradeDialog,
        handleUpgrade: onHandleUpgrade,
        handleCloseDialogs,
        openUpgradeDialog,
        submitUpgradeLoading,
        upgradeDialogOpen,
        openAwsDialog,
        openUserDialog,
        openTemplateDialog,
        handleSubmitAws: handleSubmitAwsCredentials,
        awsLoading,
      }}
    >
      {children}
      <UpgradeSubscriptionDialog />
      <DialogFormFrame
        title={dialogTitles[dialogType] || ''}
        open={dialogOpen}
        onClose={handleCloseDialogs}
        formComponent={getDialogComponent()}
      />
    </SubscriptionContext.Provider>
  );
};

SubscriptionContext.propTypes = {
  children: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    company: state.company,
    subscription: state.company?.subscription,
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(SubscriptionProvider);
