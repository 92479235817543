import React, { useRef, useState } from 'react';
import {
  Box,
  Divider,
  Drawer,
  styled,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useLazyLoading } from 'state/hooks';
import { deleteTemplate, updateTemplateTags } from 'state/actions';
import { useHistory } from 'react-router-dom';
import {
  AlertDialog,
  LoadingButton,
  LogoLoader,
  StyledInvertedButton,
  PermissionsWrapper,
} from 'components/Base';
import { TemplateTags } from 'components/Menu/Tags';
import MetadataForm from './MetadataForm';
import Moment from 'react-moment';
import StatusChip from './StatusChip';
import HtmlSourceDialog from './HtmlSouceDialog';
import useClipboard from 'components/hooks/useClipboard';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  height: `calc((100vh - ${theme.custom.appBarHeight}px)`,

  '& .MuiDrawer-paper': {
    width: '380px',
    zIndex: 2,
  },
}));

const DrawerMain = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  flexGrow: 1,
}));

const DrawerFooter = styled('div')(({ theme }) => ({
  padding: theme.spacing(1.5, 3.75, 1.5, 2),
  backgroundColor: theme.palette.background.main,
  position: 'sticky',
  display: 'flex',
  bottom: 0,
  height: theme.custom.appBarHeight * 1,
  width: '100%',
  boxShadow: '0 0 2px 2px rgba(0, 0, 0, 0.05)',
  justifyContent: 'space-between',
}));

const DrawerRow = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const DateContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0, 1, 0),
  display: 'flex',
  fontWeight: 'bold',
}));

const Date = styled(Moment)(({ theme }) => ({
  marginLeft: 4,
  fontWeight: 400,
}));

const MoreActionsDrawer = ({
  open,
  onClose,
  template,
  saving,
  onSaveMetadata,
}) => {
  const metadataRef = useRef();
  const history = useHistory();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [htmlSourceDialogOpen, setHtmlSourceDialogOpen] = useState(false);

  const [handleDeleteTemplate, { loading: deleting }] = useLazyLoading({
    action: deleteTemplate,
    onSuccess: () => {
      setDeleteDialogOpen(false);
      history.push(`/templates`);
    },
  });

  const copy = useClipboard('Html source code copied to clipboard');
  const [updateTags] = useLazyLoading({
    action: updateTemplateTags,
  });

  if (!template) return <LogoLoader />;

  const handleSaveMetadata = () => {
    if (metadataRef.current) {
      metadataRef.current.handleSubmit();
    }
  };

  return (
    <StyledDrawer open={open} anchor="right" onClose={onClose}>
      <DrawerMain>
        <Box mx={2} my={3}>
          <Box mb={2}>
            <Box
              display="flex"
              style={{ flexDirection: 'row' }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5">Status</Typography>
              <StatusChip status={template?.status} />
            </Box>
            <Box my={0.5}>
              <Divider />
            </Box>
            <DrawerRow>
              <div>
                <DateContainer>
                  Created:
                  <Date format=" MMM‚ D, YYYY, hh:mm" utc local>
                    {template.createdTimestamp}
                  </Date>
                </DateContainer>
                <Box my={1}>
                  <DateContainer>
                    Last edited:
                    <Date format=" MMM‚ D, YYYY, hh:mm" utc local>
                      {template.updatedTimestamp}
                    </Date>
                  </DateContainer>
                </Box>
                {template.awsAccountId && (
                  <Box mt={1}>
                    <DateContainer>
                      Published:
                      <Date format=" MMM‚ D, YYYY, hh:mm" utc local>
                        {template.publishedTimestamp}
                      </Date>
                    </DateContainer>
                  </Box>
                )}
                {template.deployments.length > 0 && (
                  <Box mt={2} display="flex" alignItems="center">
                    <div
                      style={{
                        cursor: 'pointer',
                        marginRight: '4px',
                        fontWeight: 700,
                      }}
                    >
                      <Tooltip
                        describeChild
                        title={
                          'The published version of this template is also published in the accounts with given alias'
                        }
                      >
                        <span>Deployments:</span>
                      </Tooltip>
                    </div>
                    <span>
                      {template.deployments.map(
                        (account, index, array) =>
                          `${account.alias}${
                            index < array.length - 1 ? ',' : ''
                          } `
                      )}
                    </span>
                  </Box>
                )}
              </div>
            </DrawerRow>
          </Box>
        </Box>
        <Box mx={2} my={3}>
          <Box mb={2}>
            <Typography variant="h5">Template Metadata</Typography>
            <Box my={0.5} mb={1}>
              <Divider />
            </Box>
          </Box>
          <MetadataForm
            formikRef={metadataRef}
            onSubmit={onSaveMetadata}
            template={template}
          />
        </Box>
        <Box mx={2} my={3}>
          <Box mb={2}>
            <Typography variant="h5">Tags</Typography>
            <Box my={0.5} mb={1}>
              <Divider />
            </Box>
            <Box mt={2}>
              <TemplateTags template={template} onSave={updateTags} />
            </Box>
          </Box>
        </Box>
      </DrawerMain>
      <DrawerFooter>
        <StyledInvertedButton
          variant="outlined"
          onClick={() => {
            setHtmlSourceDialogOpen(true);
          }}
        >
          Show HTML
        </StyledInvertedButton>
        <StyledInvertedButton
          variant="outlined"
          onClick={() => copy(template.html)}
        >
          Copy HTML
        </StyledInvertedButton>
        <PermissionsWrapper permission={'edit_templates'}>
          <LoadingButton
            loading={saving}
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSaveMetadata}
          >
            Update
          </LoadingButton>
        </PermissionsWrapper>
      </DrawerFooter>
      <AlertDialog
        onClose={() => setDeleteDialogOpen(false)}
        title="Delete Template"
        open={deleteDialogOpen}
        text="Are you sure you want to delete this template? This action will delete the template from AWS SES as well."
        onSubmit={() => handleDeleteTemplate(template.uid)}
        submitText="Yes, Delete"
        loading={deleting}
      />
      <HtmlSourceDialog
        template={template}
        onClose={() => setHtmlSourceDialogOpen(false)}
        open={htmlSourceDialogOpen}
      />
    </StyledDrawer>
  );
};

export default MoreActionsDrawer;
