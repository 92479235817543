import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  CompanySettings,
  Aws,
  Tags,
  Templates,
  UserSettings,
} from 'containers';
import { Redirect, Route } from 'react-router-dom';
import {
  awsRoutes,
  companySettingsRoutes,
  dashboard,
  tags,
  templateRoutes,
  userRoutes,
} from './consts/routes';
import Dashboard from './containers/Dashboard';
import {
  AwsAccountsProvider,
  BugsnagProvider,
  SubscriptionProvider,
  UserProvider,
} from './components/Contexts';
import { WelcomeSlidesProvider } from './components/WelcomeSlides/Contexts';

const PrivateRoutes = ({ privateRoutes }) => {
  return (
    <Fragment>
      <UserProvider>
        <BugsnagProvider>
          {/* AwsAccountsProvider has to wrap welcome slides as its used inside */}
          <AwsAccountsProvider>
            <SubscriptionProvider>
              <WelcomeSlidesProvider>
                <Route exact path={privateRoutes}>
                  <Route exact path={dashboard} component={Dashboard} />
                  <Route exact path={templateRoutes} component={Templates} />
                  <Route exact path={tags} component={Tags} />
                  <Route exact path={awsRoutes} component={Aws} />
                  <Route
                    exact
                    path={companySettingsRoutes}
                    component={CompanySettings}
                  />
                  <Route exact path={userRoutes} component={UserSettings} />
                  <Route
                    path="*"
                    render={() => {
                      <Redirect to="/" />;
                    }}
                  />
                </Route>
              </WelcomeSlidesProvider>
            </SubscriptionProvider>
          </AwsAccountsProvider>
        </BugsnagProvider>
      </UserProvider>
    </Fragment>
  );
};

PrivateRoutes.propTypes = {
  privateRoutes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PrivateRoutes;
