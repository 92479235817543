import React, { Fragment, useEffect, useState } from 'react';
import {
  LogoLoader,
  LoadingButton,
  StyledInvertedButton,
  StyledButton,
  PermissionsWrapper,
} from 'components/Base';
import { useLazyLoading } from 'state/hooks';
import { publishTemplate, reactivateVersion } from 'state/actions';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useHistory, useParams } from 'react-router-dom';
import VersionsSelector from './VersionsSelector';
import { Box, styled, Typography, useTheme } from '@material-ui/core';
import HtmlSourceDialog from '../Shared/HtmlSouceDialog';
import ResponsivePreview from '../Shared/ResponsivePreview';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { getTemplateVersions } from 'state/actions/templates';
import { connect } from 'react-redux';
import { Metadata, SubtitleTypography, TemplatesAppBar } from '../Shared';

export const MainAreaContainer = styled('div')(({ theme }) => ({
  height: `calc(100vh - ${theme.custom.appBarHeight}px)`,
  position: 'relative',
  width: `calc(100vw - ${theme.custom.sideAreaWidth}px - ${theme.custom.drawerWidth}px)`,
  padding: '23px 24px',
}));

export const MetaContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0, 0, 0, 0),
  position: 'relative',
}));

export const SideAreaContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100vh - ${theme.custom.appBarHeight}px)`,
  width: theme.custom.sideAreaWidth,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(0, 2, 0, 2),
  overflowX: 'hidden',
  overflowY: 'auto',
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  color: theme.palette.text.main,
  fontWeight: 700,
  marginBottom: theme.spacing(1),
  marginTop: '1px',
  cursor: 'pointer',
}));

/*
 Note: this component has a lot of non-trivial logic to handle all the different cases
 - latest template is first template and its not yet published
 - latest template is first template and its published
 - latest template is later version and is published
 - latest template is later version and is not published
 - latest template is later version and has unpublished changes
 - some template version between first and last is active
 -
*/

const TemplateVersions = ({ data }) => {
  const { id } = useParams();
  const history = useHistory();
  const theme = useTheme();
  const [htmlSourceDialogOpen, setHtmlSourceDialogOpen] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [latestVersionNumber, setLatestVersionNumber] = useState(null);

  const [query, setQuery] = useQueryParams({
    number: NumberParam,
    limit: withDefault(NumberParam, 5),
  });
  const { number: selectedVersionNumber, limit } = query;

  const [handleLoadTemplateVersions, { loading, error }] = useLazyLoading({
    action: getTemplateVersions,
  });

  useEffect(() => {
    if (selectedVersion && selectedVersion.uid !== id) {
      setLatestVersionNumber(null);
      handleLoadTemplateVersions({ id, limit: limit || 5 });
    }
    if (!selectedVersionNumber && data?.latest?.length > 0) {
      setQuery({ number: data?.latest[0].versionNumber });
      setSelectedVersion(data?.latest[0]);
    } else if (!selectedVersionNumber && data?.creation) {
      setQuery({ number: data?.creation.versionNumber });
    } else if (selectedVersionNumber && data?.latest) {
      const allVersions = [...data.latest, data.creation];
      const queryParamVersion = allVersions.find(
        (item) => item.versionNumber === selectedVersionNumber
      );
      if (queryParamVersion) {
        setSelectedVersion(queryParamVersion);
      } else {
        setQuery({ number: data?.latest[0]?.versionNumber });
        setSelectedVersion(data?.latest[0]);
      }
    } else {
      handleLoadTemplateVersions({ id, limit: limit || 5 });
    }
    if (data?.latest.length > 0) {
      setLatestVersionNumber(data?.latest[0].versionNumber);
    } else {
      setLatestVersionNumber(data?.creation.versionNumber);
    }

    if (!limit) setQuery({ limit: 5 });
  }, [data, limit, selectedVersionNumber]); //eslint-disable-line

  const handleUpdateSelectedVersion = (version) => {
    setQuery({ number: version.versionNumber });
    setSelectedVersion(version);
  };
  const handleLoadMoreVersion = () => {
    const newLimit = limit + 5;
    setQuery({ limit: newLimit });
    handleLoadTemplateVersions({ id, limit: newLimit });
  };

  const [handleReactivate, { loading: reactivating }] = useLazyLoading({
    action: reactivateVersion,
  });

  const [handlePublish, { loading: publishing }] = useLazyLoading({
    action: publishTemplate,
    onSuccess: () => handleLoadTemplateVersions({ id, limit: limit || 5 }),
  });

  const handleReactivateOrPublish = () => {
    if (
      selectedVersion.versionNumber === latestVersionNumber &&
      !selectedVersion.published
    ) {
      handlePublish({ uid: id });
    } else {
      handleReactivate({ id, versionNumber: selectedVersion?.versionNumber });
    }
  };

  const handleRouteChange = () => {
    let path = `/templates/${id}/editor`;
    history.push(path);
  };

  if (error)
    return (
      <Alert severity="error">
        <AlertTitle title="Template not found for selected account" />
        We could not find your template. You might have switched the AWS Account
        while being in preview mode.
      </Alert>
    );

  if (loading || !data || !selectedVersion) return <LogoLoader />;

  return (
    <Fragment>
      <TemplatesAppBar>
        <Box mr={2}>
          <StyledInvertedButton
            variant="outlined"
            color="primary"
            onClick={() => setHtmlSourceDialogOpen(true)}
          >
            Show HTML
          </StyledInvertedButton>
        </Box>
        {selectedVersion?.isactive ? (
          <StyledButton
            variant="contained"
            color="primary"
            onClick={handleRouteChange}
            width={127}
          >
            Edit Template
          </StyledButton>
        ) : (
          <PermissionsWrapper permission={'publish_templates'}>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={reactivating || publishing}
              onClick={handleReactivateOrPublish}
            >
              {selectedVersion.publishedTimestamp
                ? 'Republish Version'
                : 'Publish Version'}
            </LoadingButton>
          </PermissionsWrapper>
        )}
      </TemplatesAppBar>
      <MainAreaContainer>
        <Metadata template={selectedVersion} />
        <ResponsivePreview template={selectedVersion} />
      </MainAreaContainer>
      <SideAreaContainer>
        <Box
          style={{
            zIndex: 8000,
            position: 'fixed',
            padding: theme.spacing(2.8, 0, 2, 1),
            top: `calc(${theme.custom.appBarHeight})`,
            right: theme.spacing(2),
            width: `calc(${theme.custom.sideAreaWidth}px - ${theme.spacing(
              3
            )}px)`,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <TitleTypography variant="h3">Recent Activity </TitleTypography>
          <SubtitleTypography variant="body1">
            {data.count} versions existing
          </SubtitleTypography>
        </Box>
        <Box pt={16}>
          <VersionsSelector
            onSelectVersion={handleUpdateSelectedVersion}
            onLoadMoreVersions={handleLoadMoreVersion}
            versions={data}
            selectedVersionNumber={selectedVersionNumber}
          />
        </Box>
      </SideAreaContainer>
      <HtmlSourceDialog
        template={selectedVersion}
        onClose={() => setHtmlSourceDialogOpen(false)}
        open={htmlSourceDialogOpen}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.templates.selectedTemplateVersions,
  };
};

export default connect(mapStateToProps)(TemplateVersions);
