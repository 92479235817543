import { setShimmerOverlay, showSuccessSnackbar } from './layout';
import {
  ADD_EMAIL_ERROR,
  ADD_EMAIL_SUCCESS,
  ADD_DOMAIN_ERROR,
  ADD_DOMAIN_SUCCESS,
  ERROR_RETRIEVING_AWS_DOMAIN_IDENTITIES,
  ERROR_RETRIEVING_AWS_EMAIL_IDENTITIES,
  REMOVE_IDENTITY_ERROR,
  REMOVE_IDENTITY_SUCCESS,
  SET_DEFAULT_EMAIL_ERROR,
  SET_DEFAULT_EMAIL_SUCCESS,
  UPDATE_SENDER_NAME_ERROR,
  UPDATE_SENDER_NAME_SUCCESS,
  UPDATE_EMAIL_VISIBILITY_SUCCESS,
  UPDATE_EMAIL_VISIBILITY_ERROR,
} from '../consts/snackbarMessages';
import { handleBackendErrorMessage } from '../utils/error_handling';

export const removeVerifiedIdentity = (identity) => ({
  type: 'SES_IDENTITY_REMOVE',
  payload: identity,
});

export const setDefaultSender = (identity) => ({
  type: 'DEFAULT_SENDER_SET',
  payload: identity,
});

export const setSenderName = (values) => ({
  type: 'EMAIL_SENDER_NAME_SET',
  payload: values,
});

export const setEmailVisibility = (values) => ({
  type: 'EMAIL_VISIBILITY_SET',
  payload: values,
});

export const setEmailIdentities = (data, searchQuery) => ({
  type: 'SES_EMAIL_IDENTITIES_SET',
  payload: { payload: data, searchQuery },
});

export const getEmailIdentities = ({
  isTestSender = false,
  search = '',
  order = 'asc',
  orderBy = 'identity',
  skip = 0,
  limit = 25,
} = {}) => async (dispatch, getState, { axios }) => {
  try {
    let url = `/identities/emails?search=${encodeURIComponent(
      search
    )}&order=${order}&order_by=${orderBy}&skip=${skip}&limit=${limit}`;

    if (isTestSender) {
      url += `&is_test_sender=true`;
    }
    const { data } = await axios.get(url);
    dispatch(setEmailIdentities(data, search));
    return data;
  } catch (error) {
    handleBackendErrorMessage(
      error,
      dispatch,
      ERROR_RETRIEVING_AWS_EMAIL_IDENTITIES
    );
    throw error;
  }
};

export const syncEmailIdentities = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(setShimmerOverlay(true));
    const { data } = await axios.post(`/identities/emails/sync`);
    dispatch(setEmailIdentities(data));
    dispatch(setShimmerOverlay(false));
    return data;
  } catch (error) {
    dispatch(setShimmerOverlay(false));
    handleBackendErrorMessage(
      error,
      dispatch,
      ERROR_RETRIEVING_AWS_EMAIL_IDENTITIES
    );
    throw error;
  }
};

export const setDomainIdentities = (data) => ({
  type: 'SES_DOMAIN_IDENTITIES_SET',
  payload: data,
});

export const getDomainIdentities = ({
  order = 'asc',
  orderBy = 'domainName',
  skip = 0,
  limit = 25,
} = {}) => async (dispatch, getState, { axios }) => {
  try {
    dispatch(setShimmerOverlay(true));
    //const { data } = await axios.get(`/identities/domains?order=${order}&order_by=${orderBy}&skip=${skip}&limit=${limit}`);
    const { data } = await axios.get(
      `/identities/domains?skip=${skip}&limit=${limit}`
    );
    dispatch(setDomainIdentities(data));
    dispatch(setShimmerOverlay(false));

    return data;
  } catch (error) {
    dispatch(setShimmerOverlay(false));
    handleBackendErrorMessage(
      error,
      dispatch,
      ERROR_RETRIEVING_AWS_DOMAIN_IDENTITIES
    );
    throw error;
  }
};

export const syncDomainIdentities = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(setShimmerOverlay(true));
    const { data } = await axios.post(`/identities/domains/sync`);
    dispatch(setDomainIdentities(data));
    dispatch(setShimmerOverlay(false));
    return data;
  } catch (error) {
    dispatch(setShimmerOverlay(false));
    handleBackendErrorMessage(
      error,
      dispatch,
      ERROR_RETRIEVING_AWS_DOMAIN_IDENTITIES
    );
    throw error;
  }
};
export const getDomainIdentityDetails = (id) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.get(`/identities/domains/${id}`);
    return data;
  } catch (error) {
    if (error.response.status === 410) {
      return error.response;
    }
    handleBackendErrorMessage(
      error,
      dispatch,
      ERROR_RETRIEVING_AWS_DOMAIN_IDENTITIES
    );
    throw error;
  }
};

export const verifyEmailAddress = ({ email }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    await axios.post(`/identities/emails`, { email });
    dispatch(showSuccessSnackbar(ADD_EMAIL_SUCCESS));
  } catch (error) {
    handleBackendErrorMessage(
      error,
      dispatch,
      ADD_EMAIL_ERROR.concat(error.response.data.detail)
    );
    dispatch(setShimmerOverlay(false));
    throw error;
  }
};

export const verifyDomain = ({
  domain,
  addDkim,
  addMailFrom,
  mailFromSubdomain,
}) => async (dispatch, getState, { axios }) => {
  try {
    const payload = { domain: domain, dkim: addDkim };
    if (addMailFrom) {
      payload.subDomain = mailFromSubdomain;
    }
    dispatch(setShimmerOverlay(true));
    const { data } = await axios.post('/identities/domains', payload);
    dispatch(showSuccessSnackbar(ADD_DOMAIN_SUCCESS));
    const { data: domainData } = await axios.get(`/identities/domains`);
    dispatch(setDomainIdentities(domainData));
    dispatch(setShimmerOverlay(false));
    return data;
  } catch (error) {
    handleBackendErrorMessage(
      error,
      dispatch,
      ADD_DOMAIN_ERROR.concat(error.response.data.detail)
    );
    dispatch(setShimmerOverlay(false));
    throw error;
  }
};

export const removeIdentity = ({ uid }) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(setShimmerOverlay(true));
    const { data } = await axios.delete(`/identities/${uid}`);
    dispatch(removeVerifiedIdentity(uid));
    dispatch(showSuccessSnackbar(REMOVE_IDENTITY_SUCCESS));
    dispatch(setShimmerOverlay(false));
    return data;
  } catch (error) {
    handleBackendErrorMessage(
      error,
      dispatch,
      REMOVE_IDENTITY_ERROR.concat(error.response.data.detail)
    );
    dispatch(setShimmerOverlay(false));
    throw error;
  }
};

export const setDefaultEmail = (uid) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    await axios.patch(`identities/${uid}/default`);
    dispatch(setDefaultSender(uid));
    dispatch(showSuccessSnackbar(SET_DEFAULT_EMAIL_SUCCESS));
    return true;
  } catch (error) {
    handleBackendErrorMessage(
      error,
      dispatch,
      SET_DEFAULT_EMAIL_ERROR.concat(error.response.data.detail)
    );
    dispatch(setShimmerOverlay(false));
    throw error;
  }
};

export const updateSenderName = (uid, newSender) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const {
      data: { identity, senderName },
    } = await axios.patch(`identities/${uid}/sender`, {
      senderName: newSender,
    });

    dispatch(setSenderName({ identity, senderName }));
    dispatch(showSuccessSnackbar(UPDATE_SENDER_NAME_SUCCESS));
    return true;
  } catch (error) {
    handleBackendErrorMessage(
      error,
      dispatch,
      UPDATE_SENDER_NAME_ERROR.concat(error.response.data.detail)
    );
    dispatch(setShimmerOverlay(false));
    throw error;
  }
};

export const updateIsTestSenderName = (uid, value) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const {
      data: { identity, isTestSender },
    } = await axios.patch(
      `identities/emails/${uid}/visibility?visible=${value}`
    );

    dispatch(setEmailVisibility({ identity, isTestSender }));
    dispatch(showSuccessSnackbar(UPDATE_EMAIL_VISIBILITY_SUCCESS));
    return true;
  } catch (error) {
    handleBackendErrorMessage(
      error,
      dispatch,
      UPDATE_EMAIL_VISIBILITY_ERROR.concat(error.response.data.detail)
    );
    dispatch(setShimmerOverlay(false));
    throw error;
  }
};

export const changeBulkEmailIdentityVisibility = (visible) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { data } = await axios.put(
      `identities/emails/visibility?visible=${visible}`
    );

    dispatch(setEmailIdentities(data));
    dispatch(showSuccessSnackbar(UPDATE_EMAIL_VISIBILITY_SUCCESS));
    return true;
  } catch (error) {
    handleBackendErrorMessage(
      error,
      dispatch,
      UPDATE_EMAIL_VISIBILITY_ERROR.concat(error.response.data.detail)
    );
    dispatch(setShimmerOverlay(false));
    throw error;
  }
};
