import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { StyledAppBar, StyledEnd } from 'components/Structure';
import { useHistory, useParams } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import {
  BlocksIcon,
  Close,
  DeleteOutline,
  History,
  MoreVert,
  Visibility,
  EditIcon,
} from 'assets/icons';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { connect } from 'react-redux';
import { useAsyncAction } from 'state';
import { deleteTemplate, getTemplate } from 'state/actions';
import { AlertDialog } from 'components/Base';
import { useLazyLoading } from 'state/hooks';
import TemplateBlocksDialog from '../Editor/TemplateBlocksDialog';

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  color: theme.palette.text.main,
  fontWeight: 600,
}));

const StyledIconButton = styled(IconButton)(
  ({ theme, active, isdisabled }) => ({
    padding: '0px 8px',
    '& .MuiSvgIcon-root': {
      color: isdisabled
        ? theme.palette.grey[300]
        : !active
        ? theme.palette.grey.inactive
        : theme.palette.text.primary,
    },
  })
);

const TemplateAppBar = ({ template, children }) => {
  // eslint-disable-next-line
  const history = useHistory();
  const { id } = useParams();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [blockDialogOpen, setBlockDialogOpen] = useState(false);

  const [handleDeleteTemplate, { loading: deleting }] = useLazyLoading({
    action: deleteTemplate,
    onSuccess: () => {
      setDeleteDialogOpen(false);
      history.push(`/templates`);
    },
  });

  useAsyncAction({
    action: getTemplate,
    values: { id },
  });

  const [drawerOpen, setDrawerOpen] = useQueryParam(
    'moreActions',
    BooleanParam
  );

  const urlFragments = history.location.pathname.split('/');
  const currentScreen = urlFragments[urlFragments.length - 1];

  const handleOpenTemplateVersions = () => {
    history.push(`/templates/${template.uid}/versions`);
  };

  const handleOpenTemplateEditor = () => {
    history.push(`/templates/${template.uid}/editor`);
  };

  const handleOpenTemplatePreview = () => {
    history.push(`/templates/${template.uid}/preview`);
  };

  const icons = [
    {
      Icon: EditIcon,
      tooltip: 'Edit Template',
      onClick: handleOpenTemplateEditor,
      disabled: false,
      active: currentScreen === 'editor',
    },
    {
      Icon: Visibility,
      tooltip: 'Template Preview',
      onClick: handleOpenTemplatePreview,
      disabled: false,
      active: currentScreen === 'preview',
    },
    {
      Icon: History,
      tooltip: 'Template Versions',
      onClick: handleOpenTemplateVersions,
      disabled: false,
      active: currentScreen === 'versions',
    },
    {
      Icon: BlocksIcon,
      tooltip: 'View Blocks',
      onClick: () => setBlockDialogOpen(true),
      disabled: false,
    },
    {
      Icon: DeleteOutline,
      tooltip: 'Delete',
      onClick: () => {
        setDeleteDialogOpen(true);
      },
      disabled: false,
      active: false,
    },
  ];

  if (currentScreen === 'editor') {
    icons.push({
      Icon: drawerOpen ? Close : MoreVert,
      tooltip: drawerOpen ? 'Close Menu' : 'Open Menu',
      onClick: () => {
        drawerOpen ? setDrawerOpen(false) : setDrawerOpen(true);
      },
      disabled: false,
      active: drawerOpen,
    });
  }
  return (
    <>
      <StyledAppBar position="fixed">
        <Box ml={3} mb={1}>
          <StyledTitle variant="h1">{template?.templateName || ''}</StyledTitle>
        </Box>
        <StyledEnd>
          <Box
            display="flex"
            style={{ flexDirection: 'row' }}
            alignItems="center"
            mr={2}
          >
            {children && children}
          </Box>
          {icons.map(({ Icon, tooltip, onClick, disabled, active }) => (
            <Tooltip title={tooltip} key={tooltip}>
              <StyledIconButton
                onClick={onClick}
                active={active ? 1 : 0}
                isdisabled={disabled ? 1 : 0}
              >
                <Icon color="inherit" />
              </StyledIconButton>
            </Tooltip>
          ))}
        </StyledEnd>
      </StyledAppBar>
      <AlertDialog
        onClose={() => setDeleteDialogOpen(false)}
        title="Delete Template"
        open={deleteDialogOpen}
        text="Are you sure you want to delete this template? This action will delete the template from AWS SES as well."
        onSubmit={() => handleDeleteTemplate(template.uid)}
        submitText="Yes, Delete"
        loading={deleting}
      />
      <TemplateBlocksDialog
        open={blockDialogOpen}
        onClose={() => setBlockDialogOpen(false)}
        templateId={template?.uid}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    template:
      state.templates.selectedTemplate ||
      state.templates.selectedTemplateVersions?.latest[0],
  };
};

export default connect(mapStateToProps)(TemplateAppBar);
