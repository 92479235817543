import React, { Fragment, useState } from 'react';
import {
  AlertDialog,
  OverviewTable,
  SettingsHeader,
  hasPermission,
  LogoLoader,
} from 'components/Base';
import { DetailDrawer } from 'components/Structure';
import {
  createBlock,
  removeBlock,
  duplicateBlockAction,
  fetchBlocks,
  updateBlock,
} from 'state/actions';
import { connect } from 'react-redux';
import { useLazyLoading, useAsyncAction, useLocalStorage } from 'state/hooks';
import { actionTooltips, blockColumns, infoBoxTexts } from 'consts';
import { EditIcon, DeleteOutline, ContentCopy, RenameIcon } from 'assets/icons';
import CreateReusableBlockDialog from './CreateReusableBlockDialog';
import RenameReusableBlockDialog from './RenameReusableBlockDialog';
import ReusableBlockDetails from './ReusableBlockDetails';
import { ArrayParam, useQueryParam, StringParam } from 'use-query-params';
import { useHistory } from 'react-router-dom';

const ReusableBlocks = ({ blocks, count, userPermissions }) => {
  const history = useHistory();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState('');
  const [selectedRenameBlock, setSelectedRenameBlock] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useLocalStorage('blockRowsPerPage', 10);
  const [blockId, setBlockId] = useQueryParam('blockId', StringParam);
  const [filteredTags] = useQueryParam('filteredTags', ArrayParam);

  const { loading } = useAsyncAction({
    action: fetchBlocks,
    values: { tagIds: filteredTags },
  });

  const [handleDelete, { loading: deleteLoading }] = useLazyLoading({
    action: removeBlock,
    onSuccess: () => {
      handleClose();
    },
  });

  const [handleDuplicate, { loading: duplicateLoading }] = useLazyLoading({
    action: duplicateBlockAction,
    onSuccess: (block) => {
      history.push(`?blockId=${block.id}`);
    },
  });

  const [handleCreateBlock, { loading: createLoading }] = useLazyLoading({
    action: createBlock,
    onSuccess: (newBlock) => {
      setCreateDialogOpen(false);
      setBlockId(newBlock.id);
    },
  });

  const [handleRename, { loading: renameLoading }] = useLazyLoading({
    action: updateBlock,
    onSuccess: () => setSelectedRenameBlock(null),
  });

  const handleClose = () => {
    setDeleteDialogOpen(false);
    setSelectedBlock(undefined);
  };

  const handleOpenDeleteDialog = (block) => {
    setSelectedBlock(block);
    setDeleteDialogOpen(true);
  };

  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true);
  };

  const handleRenameBlock = async ({ label, description }) => {
    await handleRename({
      id: selectedRenameBlock.id,
      values: {
        label: label,
        description: description,
        design: selectedRenameBlock.design,
      },
    });
  };

  if (loading || !blocks) {
    return <LogoLoader />;
  }

  const deleteConfirmationText =
    selectedBlock?.templateCount > 0
      ? `Are you sure you want to delete this block? Its used in ${selectedBlock.templateCount} template(s). All references to this block will be removed. This action cannot be undone.`
      : 'Are you sure you want to delete this block? This action cannot be undone.';
  return (
    <Fragment>
      <SettingsHeader
        buttonText="Add New Block"
        title="Reusable Blocks"
        tooltip
        onClick={handleOpenCreateDialog}
        itemsCount={count}
        primaryActionPermission={'create_templates'}
        infoText={infoBoxTexts.blocks}
      />
      <OverviewTable
        data={blocks}
        columns={blockColumns}
        total={count}
        loading={loading || deleteLoading || duplicateLoading}
        actions={[
          {
            icon: <EditIcon size="26" />,
            label: 'Edit Block',
            onClick: (block) => setBlockId(block.id),
            enabledCondition: () =>
              hasPermission(userPermissions, 'edit_templates'),
            disabledTooltipText: actionTooltips.edit_blocks,
          },
          {
            icon: <RenameIcon size="26" />,
            label: 'Rename Block',
            onClick: (block) => setSelectedRenameBlock(block), // Store the entire block for renaming
            enabledCondition: () =>
              hasPermission(userPermissions, 'edit_templates'),
            disabledTooltipText: actionTooltips.edit_blocks,
          },
          {
            icon: <ContentCopy size="26" />,
            label: 'Duplicate Block',
            onClick: (block) => handleDuplicate(block.id),
            enabledCondition: () =>
              hasPermission(userPermissions, 'create_templates'),
            disabledTooltipText: actionTooltips.write_blocks,
          },
          {
            icon: <DeleteOutline size="26" />,
            label: 'Remove Block',
            onClick: handleOpenDeleteDialog,
            enabledCondition: () =>
              hasPermission(userPermissions, 'delete_templates'),
            disabledTooltipText: actionTooltips.delete_blocks,
          },
        ]}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
      <AlertDialog
        onClose={handleClose}
        title="Delete Block"
        open={deleteDialogOpen}
        text={deleteConfirmationText}
        onSubmit={() => handleDelete(selectedBlock)}
        submitText="Yes, Delete"
        loading={deleteLoading}
      />
      <CreateReusableBlockDialog
        loading={createLoading}
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onSubmit={handleCreateBlock}
      />
      <RenameReusableBlockDialog
        loading={renameLoading}
        open={!!selectedRenameBlock}
        onClose={() => setSelectedRenameBlock(null)}
        onSubmit={handleRenameBlock}
        selectedBlock={selectedRenameBlock}
      />
      <DetailDrawer
        anchor="right"
        open={!!blockId}
        PaperProps={{
          style: {
            position: 'absolute',
            right: 0,
            width: `calc(100% - 220px)`,
          },
        }}
        onClose={() => setBlockId(undefined)}
      >
        <ReusableBlockDetails />
      </DetailDrawer>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    blocks:
      state.blocks?.entities.filter((item) => item.state !== 'DELETED') ?? [],
    count: state.blocks?.count ?? 0,
    userPermissions: state.user?.permissions ?? [],
  };
};

export default connect(mapStateToProps)(ReusableBlocks);
