import React from 'react';
import PropTypes from 'prop-types';
import { DialogFormFrame } from 'components/Base';
import CreateTagForm from './CreateTagForm';
import { createTag, updateTag } from 'state/actions';
import { useLazyLoading } from 'state/hooks';

const CreateTagDialog = ({ open, onClose, tag = {} }) => {
  const [handleSubmit, { loading }] = useLazyLoading({
    action: createTag,
    onSuccess: () => onClose(),
  });

  const [handleUpdate, { loading: updating }] = useLazyLoading({
    action: updateTag,
    onSuccess: () => {
      onClose();
    },
  });

  const handleUpdateTag = (values) => {
    const update = { ...tag, ...values };
    handleUpdate(update);
  };

  return (
    <DialogFormFrame
      onClose={onClose}
      open={open}
      title={'Create a new tag'}
      formComponent={
        <CreateTagForm
          loading={loading || updating}
          onClose={onClose}
          tag={tag}
          onSubmit={tag ? handleUpdateTag : handleSubmit}
        />
      }
    />
  );
};

CreateTagDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateTagDialog;
