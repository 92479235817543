import React, { Fragment, useState } from 'react';
import { AlertDialog, hasPermission } from 'components/Base';
import { DuplicateTemplateDialog } from 'components/Menu/Templates';
import { useHistory } from 'react-router-dom';
import { actionTooltips, templateColumns } from 'consts';
import { Copy, DeleteOutline, History, Send } from 'assets/icons';
import { deleteTemplate as deleteTemplateAction } from 'state/actions';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import TemplateFilterBar from '../Shared/TemplateFilterBar';
import TemplatesTable from './TemplatesTable';

const TemplatesList = ({
  templates,
  templateCount,
  deleteTemplate,
  userPermissions,
}) => {
  const history = useHistory();
  const [deleteTemplateLoading, setDeleteTemplateLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const handleSendMail = ({ uid }) => {
    let path = `/templates/${uid}/preview`;
    history.push(path);
  };

  const handleDeleteOpenDialog = ({ uid }) => {
    setSelectedTemplate(uid);
    setDeleteDialogOpen(true);
  };

  const handleOpenTemplateVersions = ({ uid }) => {
    history.push(`/templates/${uid}/versions`);
  };

  const handleDeleteTemplate = () => {
    setDeleteTemplateLoading(true);
    deleteTemplate(selectedTemplate).then(() => {
      setDeleteTemplateLoading(false);
      setDeleteDialogOpen(false);
      setSelectedTemplate(null);
    });
  };

  const handleDuplicateDialogOpen = (template) => {
    setSelectedTemplate(template);
    setDuplicateDialogOpen(true);
  };

  const handleDuplicateDialogClose = () => {
    setSelectedTemplate('');
    setDuplicateDialogOpen(false);
  };

  const handleClose = () => {
    setDeleteDialogOpen(false);
    setSelectedTemplate('');
  };

  const templateActions = [
    {
      icon: <Copy size="26" />,
      label: 'Duplicate',
      onClick: handleDuplicateDialogOpen,
      enabledCondition: () =>
        hasPermission(userPermissions, 'create_templates'),
      disabledTooltipText: actionTooltips.create_templates,
    },
    {
      icon: <History size="26" />,
      label: 'Versions',
      onClick: handleOpenTemplateVersions,
    },
    {
      icon: <Send size="26" />,
      label: 'Send',
      onClick: handleSendMail,
    },
    {
      icon: <DeleteOutline size="26" />,
      label: 'Delete',
      onClick: handleDeleteOpenDialog,
      enabledCondition: () =>
        hasPermission(userPermissions, 'delete_templates'),
      disabledTooltipText: actionTooltips.delete_templates,
    },
  ];

  return (
    <Fragment>
      <Box mb={2}>
        <TemplateFilterBar />
      </Box>
      <TemplatesTable
        title=""
        data={templates}
        columns={templateColumns}
        actions={templateActions}
        total={templateCount}
      />
      <AlertDialog
        onClose={handleClose}
        title="Delete Template"
        open={deleteDialogOpen}
        text="Are you sure you want to delete this Template? This action will delete the template from AWS SES as well."
        onSubmit={handleDeleteTemplate}
        submitText="Yes, Delete"
        loading={deleteTemplateLoading}
      />
      <DuplicateTemplateDialog
        onClose={handleDuplicateDialogClose}
        open={duplicateDialogOpen}
        template={selectedTemplate}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    templates: state.templates.entities || [],
    templateCount: state.templates?.count ?? 10,
    templateFilterTags: state.layout.templateFilterTags,
    awsConnected: state.aws.all?.length > 0,
    userPermissions: state.user?.permissions || [],
  };
};

const mapDispatchToProps = {
  deleteTemplate: deleteTemplateAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesList);
