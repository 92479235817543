const INITIAL_STATE = {
  emails: {
    entities: [],
    count: 0,
  },
  domains: {
    entities: [],
    count: 0,
  },
};

const applySetEmailIdentities = (state, data) => {
  const updatedEntities = [...state.emails.entities];
  const { payload, searchQuery } = data.payload;

  if (!searchQuery) {
    payload.entities.forEach((newIdentity) => {
      const exists = updatedEntities.some(
        (existingIdentity) => existingIdentity.identity === newIdentity.identity
      );

      if (!exists) {
        updatedEntities.push(newIdentity);
      }
    });
  }

  const entities = searchQuery ? payload.entities : updatedEntities;

  return {
    ...state,
    emails: {
      entities,
      count: payload.count,
    },
  };
};

const applySetDomainsIdentities = (state, { payload }) => {
  return {
    ...state,
    domains: payload,
  };
};

const setDefaultSender = (state, { payload }) => {
  const mails = state.emails.entities.map((mail) => ({
    ...mail,
    isDefault: mail.uid === payload,
  }));
  return {
    ...state,
    emails: {
      ...state.emails,
      entities: mails,
    },
  };
};

const setSenderName = (state, { payload }) => {
  const { identity, senderName } = payload;
  const mails = state.emails.entities.map((mail) =>
    mail.identity === identity ? { ...mail, senderName } : mail
  );
  return {
    ...state,
    emails: {
      ...state.emails,
      entities: mails,
    },
  };
};

const setEmailVisibility = (state, { payload }) => {
  const { identity, isTestSender } = payload;
  const mails = state.emails.entities.map((mail) =>
    mail.identity === identity ? { ...mail, isTestSender } : mail
  );
  return {
    ...state,
    emails: {
      ...state.emails,
      entities: mails,
    },
  };
};

const applyRemoveIdentity = (state, { payload }) => {
  const verifiedMailAddresses = state.emails.entities.filter(
    (item) => item.uid !== payload
  );
  const verifiedDomains = state.domains.entities.filter(
    (item) => item.uid !== payload
  );
  return {
    ...state,
    emails: {
      ...state.emails,
      entities: verifiedMailAddresses,
    },
    domains: {
      ...state.domains,
      entities: verifiedDomains,
    },
  };
};

const identityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SES_EMAIL_IDENTITIES_SET': {
      return applySetEmailIdentities(state, action);
    }
    case 'SES_DOMAIN_IDENTITIES_SET': {
      return applySetDomainsIdentities(state, action);
    }
    case 'SES_IDENTITY_REMOVE': {
      return applyRemoveIdentity(state, action);
    }
    case 'DEFAULT_SENDER_SET': {
      return setDefaultSender(state, action);
    }
    case 'EMAIL_SENDER_NAME_SET': {
      return setSenderName(state, action);
    }
    case 'EMAIL_VISIBILITY_SET': {
      return setEmailVisibility(state, action);
    }
    default:
      return state;
  }
};

export default identityReducer;
